import React from "react"
import { rhythm } from "../utils/typography"
import MainHeader from "../components/mainHeader"
import MainFooter from "../components/mainFooter"

const Layout = ({ title, children }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(32),
      }}
      className="layout"
    >
      <MainHeader title={title} />
      <main>{children}</main>
      <MainFooter />
    </div>
  )
}

export default Layout

// import React from "react"
// import { Link } from "gatsby"

// const Layout = ({ location, title, children }) => {
//   const rootPath = `${__PATH_PREFIX__}/`
//   const isRootPath = location.pathname === rootPath
//   let header

//   if (isRootPath) {
//     header = (
//       <h1 className="main-heading">
//         <Link to="/">{title}</Link>
//       </h1>
//     )
//   } else {
//     header = (
//       <Link className="header-link-home" to="/">
//         {title}
//       </Link>
//     )
//   }

//   return (
//     <div className="global-wrapper" data-is-root-path={isRootPath}>
//       <header className="global-header">{header}</header>
//       <main>{children}</main>
//       <footer>
//         © {new Date().getFullYear()}
//       </footer>
//     </div>
//   )
// }

// export default Layout


import React from "react"
import { rhythm, scale } from "../utils/typography"
import { Link } from "gatsby"

const MainHeader = ({ title }) => {
  return (
    <header 
      style={{
        marginBottom: rhythm(0),
        marginTop: 0
      }}
    >
      <h1
        style={{
          marginTop: 0,
          marginBottom: rhythm(0.25),
          ...scale(1.5),
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
      <ul className="list-inline">
        <li><Link to={`/about/`}>About</Link></li>
        <li><Link to={`/archive/`}>Archive</Link></li>
        <li><Link to={`/tags/`}>Tags</Link></li>
      </ul>
    </header>
  )
}


export default MainHeader

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faMedium } from '@fortawesome/free-brands-svg-icons'
import { faRss } from '@fortawesome/free-solid-svg-icons'

const MainFooter = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
            }
          }
        }
      }
    `
  )
  const twitterHandle = site.siteMetadata.social.twitter

  return (
    <footer style={{paddingBottom: rhythm(1)}}>
      <div style={{float: "left"}}>
        <ul className="list-inline social-links">
          <li>
            <a href={`https://medium.com/@severinperez`}>
              <FontAwesomeIcon icon={faMedium} />
            </a>
          </li>
          <li>
            <a href={`https://twitter.com/${twitterHandle}`}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </li>
          <li>
            <Link to={`https://github.com/sevperez`}>
              <FontAwesomeIcon icon={faGithub} />
            </Link>
          </li>
          <li>
            <Link to={`/rss.xml`}>
              <FontAwesomeIcon icon={faRss} />
            </Link>
          </li>
        </ul>
      </div>
      <div style={{float: "right"}}>
        <small>
          © Severin Perez, {new Date().getFullYear()}
        </small>
      </div>
    </footer>
  )
}

export default MainFooter
